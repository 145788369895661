<template>
	<div class="body-block" :class="{ 'sidebar-xs': sidebarHide, 'sidebar-mobile-main': sidebarMobile }">
		<div class="navbar navbar-expand-md navbar-dark">
			<div class="navbar-brand">
			</div>
			<div class="d-md-none">
				<button class="navbar-toggler sidebar-mobile-main-toggle" type="button" @click="onChangeSidebarMobile">
					<i class="icon icon-menu"></i>
				</button>
				<button class="navbar-toggler" type="button" @click="onChangeNavbarMobileShow">
					<i :class="{ 'icon icon-chevron-up': navbarMobileShow, 'icon icon-chevron-down': !navbarMobileShow }"></i>
				</button>
			</div>

			<div class="collapse navbar-collapse" :class="{ show: navbarMobileShow }">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block" @click="onChangeSidebar">
							<i class="icon icon-menu"></i>
						</a>
					</li>
				</ul>

				<span class="badge bg-success mx-md-2 mr-md-auto" v-if="online">Online</span>
				<span class="badge bg-danger mx-md-2 mr-md-auto" v-else>Offline</span>

				<ul class="navbar-nav">
					<li class="nav-item dropdown">
						<a class="btn navbar-nav-link caret-0" @click="onMute" :title="mute ? 'Включить уведомления' : 'Выключить уведомления'">
							<i class="icon icon-volume-mute2" v-if="mute"></i>
							<i class="icon icon-volume-high" v-else></i>
						</a>
					</li>
					<Messages />
					<User />
				</ul>
			</div>
		</div>
		<!-- /main navbar -->

		<!-- Page content -->
		<div class="page-content">
			<!-- Main sidebar -->
			<Sidebar />
			<!-- /main sidebar -->

			<!-- Main content -->
			<div class="content-wrapper">
				<!-- Page header -->
				<div class="page-header page-header-light">
					<div id="page-header"></div>

					<BreadCrumb />
				</div>
				<!-- /page header -->

				<!-- Content area -->
				<div class="content">
					<div id="alert-panel"></div>
					<slot />
				</div>
				<!-- /content area -->
			</div>
			<!-- /main content -->
		</div>
		<button class="scroll-button" @click="toggleScroll">
			<i :class="{ 'icon icon-chevron-up': isScrolledDown, 'icon icon-chevron-down': !isScrolledDown }"></i>
		</button>
	</div>
</template>

<script>


import { computed, onMounted, reactive, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';

import { availabeRole } from '@/core/helpers/access';
import { sidebarHide, sidebarMobile, navbarMobileShow, pageTitle } from "@/core/layouts";

import User from "./Dropdown/User";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Messages from "./Dropdown/Messages";
import BreadCrumb from "./BreadCrumb";

import { profile } from '@/api/users';

import { config } from '@/config';

export default {
	name: "Default",

	components: {
		Sidebar, Messages, User, BreadCrumb, Footer
	},

	setup() {
		const { state, dispatch } = useStore();

		const userProfile = reactive({});

		onMounted(async () => {
			if (state.user.id) {
				const response = await profile(state.user.id);

				Object.assign(userProfile, response);
			}

			window.addEventListener('scroll', handleScroll);
		})

		const isScrolledDown = ref(false);
		const lastScrollPosition = ref(0);

		const toggleScroll = () => {
			if (isScrolledDown.value) {
				lastScrollPosition.value = document.documentElement.scrollTop;

				window.scrollTo({ top: 0 });

				isScrolledDown.value = false;
			} else {
				if (lastScrollPosition.value > 180) {
					window.scrollTo({ top: lastScrollPosition.value });

					isScrolledDown.value = true;
				}
			}
		};

		const handleScroll = () => {
			const currentScrollPosition = document.documentElement.scrollTop;
			isScrolledDown.value = currentScrollPosition > 180;
		};

		onUnmounted(() => {
			window.removeEventListener('scroll', handleScroll);
		});

		return {
			sidebarHide,
			sidebarMobile,
			navbarMobileShow,
			pageTitle,
			config,
			state,
			userProfile,
			user: computed(() => state.user),
			mute: computed(() => state.mute),
			onMute: () => dispatch('mute'),
			onLogout: () => dispatch('logout'),
			availabeRole,

			onChangeSidebar: () => {
				sidebarMobile.value = true;
				sidebarHide.value = sidebarHide.value;
			},
			onChangeSidebarMobile: () => {
				sidebarMobile.value = !sidebarMobile.value;
			},
			onChangeNavbarMobileShow: () => navbarMobileShow.value = !navbarMobileShow.value,
			online: computed(() => state.online),
			isScrolledDown,
			toggleScroll
		}
	}
}
</script>

<style scoped>
.text-semibold {
	font-weight: 500;
}

.topbar-logo {
	font-size: 20px;
	max-height: 25px;
	border: 0;
	display: inline-block;
	padding-top: 0.5rem;
	padding-left: 0rem;
	padding-bottom: 1rem;
	margin-right: -2.2rem;
	line-height: inherit;
	white-space: nowrap;
}

.logo {
	height: 2rem;
}

.scroll-button {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 9999;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #c3c8ce;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s;
	border: 0;
}

.scroll-button:hover {
	background-color: #b3b5b8;
}

.scroll-button:focus,
.scroll-button:active {
	border: 0;
	outline: none;
}

.scroll-button i {
	font-size: 20px;
}
</style>
